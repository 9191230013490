import { Routes, Route } from "react-router-dom";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";

export default function EntryRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="dashboard" element={<Dashboard />} />
      </Routes>
    </>
  );
}
